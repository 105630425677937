<template>
    <layout :classes="classes" :routeName="routeName" :navigation="navigation" :sidebar="sidebar" :scope="scope">
        <div class="heading-container">
            <div class="back-title-container">
                <BackButton />
                <h1 class="title">{{$t(`profile.${profileTypeToCreate}.create.title`)}}</h1>
            </div>
        </div>

        <p class="heading-subtitle">Vous n'avez pas de compte de ce type présentement. Remplissez les informations ci-dessous pour en créé un</p>

        <div class="row">
            <div class="left">
                <CreateProfileCard :profileTypeToCreate="profileTypeToCreate" />
            </div>
        </div>
    </layout>
</template>

<script>
import { mapGetters } from 'vuex';

import Layout from "../../../../components/Layout.vue";
import BackButton from '../../../../components/Navigation/BackButton.vue';
import CreateProfileCard from "../../../../components/Cards/GetStarted/Step2WorkerCard.vue"

export default {
    name: "get-started",

    components: {
        Layout,
        BackButton,
        CreateProfileCard
    },

    props: {
        navigation: {
            type: String,
            default: 'simple'
        },

        sidebar: {
            type: Boolean,
            default: false
        },

        scope: {
            type: Object,
            default() {
                return {};
            }
        },

        context: {
            type: String,
            default: 'onboarding'
        }
    },

    computed: {
        ...mapGetters([
            'scopes'
        ])
    },

    data() {
        return {
            profileTypeToCreate: '',
            routeName: 'create-profile',
            classes: {
                'dashboard': true
            }
        }
    },

    mounted() {
        const self = this;
        if(self.scopes.includes('worker') && !self.scopes.includes('volunteer')) {
            self.profileTypeToCreate = 'volunteer'
        }

        if(self.scopes.includes('volunteer') && !self.scopes.includes('worker')) {
            self.profileTypeToCreate = 'worker'
        }
    }
}
</script>