<template>
    <div v-if="route" class="cta cta-slim cta-outline dark arrow" @click="$utils._navigate_to_name(route)"><font-awesome-icon :icon="['fa', 'arrow-left']" /></div>
    <div v-else class="cta cta-slim cta-outline dark arrow" @click="trigger_navigate_back()"><font-awesome-icon :icon="['fa', 'arrow-left']" /></div>
</template>

<script>

export default {
    name: "BackButton",
    
    props: {
        save_progress: {
            type: Boolean,
            default: false,
        },

        route: {
            type: String,
            default: null
        }
    },

    methods: {
        trigger_navigate_back: function() {
            const self = this;
            if(self.save_progress) { self.$emit('navigate_back_triggered'); }
            else { self.$utils._navigate_back() }
        }
    }
};
</script>